import React from 'react'
import { Link } from 'react-router-dom'
import { LogIn, PlusCircle, Eye } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { useContext, homeContext } from 'react'
import './Center.css'
import { appContext } from './App'

function Center() {
  const {token, setToken, Favorite} = useContext(appContext)
  const navigate= useNavigate()

  return (
    <div className='main'>
        <div className='side'>
           <div className='port'>
           { token ?  (
                <>
                 <Link to='/adPost'  className='por'><PlusCircle  size={35} color='black' /></Link>
                
                </>
            ) : (
                <>
                    <Link to='/SignIn'><LogIn size={35} color='black'/></Link>
                </>
            )
            }
          
            <Link to='/' className='por'><Eye  size={35} color='black' /></Link>
           </div>
        </div>
    </div>
  )
}

export default Center