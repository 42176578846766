import React, {useContext} from 'react'
//import 'bootstrap/dist/css/bootstrap.min.css'
import '../page/page.css'
import Tafe from './cepa/download (9).jfif'
import Wheat from './cepa/download.jfif'
import corn from './cepa/images.jfif'
import Barley from './cepa/download (1).jfif'
import Sorghum from './cepa/images (2).jfif'
import { Link } from 'react-router-dom'
//import { useTranslation } from 'react-i18next';
import { appContext } from '../App'

function Cereals() {


  const {languages, lang} = useContext(appContext)
  return (
    <div className='uv'>
<div>
</div>
<div className='all'>
        <div className='sub'>
        <Link to='/Category/Tefa'><img src={(Tafe)}  alt='profile' /></Link>
        <h1>{(languages[lang].sub.Tef)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Wheat'><img src={(Wheat)}  alt='profile'/></Link>
        <h1>{(languages[lang].sub.Wheat)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Corn'><img src={(corn)}  alt='profile'/></Link>
        <h1>{(languages[lang].sub.Corn)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Barley'><img src={(Barley)}  alt='profile'/></Link> 
        <h1>{(languages[lang].sub.Barley)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/sorghum'><img src={(Sorghum)}  alt='profile'/></Link> 
        <h1>{(languages[lang].sub.Sorghum)}</h1>
        </div>
    </div>
</div>
  )
}

export default Cereals;