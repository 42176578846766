import React from 'react'
import Function from './Fun'
import {useState, useContext} from 'react'
import {ArrowLeft} from 'react-feather'
import { Link , useNavigate, useParams} from 'react-router-dom';
import axios, { Axios } from 'axios'
import { baseUrl } from '../Constant';
import {appContext} from '../App'

//back
function ReplacePass() {
  const [value, setValue]= useState ({
    code: '',
  })
  const [error, setError]= useState({})
  const {token} = useParams()
  const {setToken} = useContext(appContext)
    const navigate = useNavigate()
  const history = useNavigate()
  async function handleSubmit(e){
    e.preventDefault()
    let password = e.target.password.value
    let confirm = e.target.confirm.value
    if(confirm != password) return alert('Password must match')
    // let formdata = new FormData()
    // formdata.append('password', password)
    

    console.log(token)
    let response = await axios(baseUrl + '/api/reset-password', {
        data: {
          password, token
        },
        method: 'post',
        // headers: {
        //     'Authorization': 'Bearer ' + token
        // }
    })
    if(response.data.success){
      alert('Password successfully reset')
        navigate('/SignIn')
    } else {
        setError(true)
    }
  }
  return (
    <div className='bodyCard'>
    <div className='homeCard'>
    <Link onClick={()=>history(-1)} ><ArrowLeft /></Link>
        <form onSubmit={handleSubmit} method='' action=''>
            <div className='SideCard'>
            <input  className='input' type='password' placeholder='Enter pass'  name='password' required />
            <input  className='input' type='password' placeholder='confirm pass'  name='confirm' required />
            {error.name && <p style={{color:"red", fontSize: "13px" }}>{error.code}</p>}
            </div>
            <div className='button'>
              {/* <button className='re'>Resend Code</button> */}
              <button className='btn'>Submit</button>
            </div>
        </form>
    </div>
   </div> 
  )
}
export default ReplacePass;
