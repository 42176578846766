import React, {useContext, useState}from 'react'
import { Link } from 'react-feather'
import Abulica from './Ora (4).png'
import { useTranslation } from 'react-i18next';
import { appContext } from '../App'


function About() {
  const { t } = useTranslation();
  const {languages, lang} = useContext(appContext)
  return (
    <div className='homeCard'>
    <img className='home' src={(Abulica)}  alt='profile' />
        <h1>Yeha Farm</h1>
        <h2>• {languages[lang].Note.Abou}</h2>
        <h2>• {languages[lang].Note.Abou1}</h2>
        <h2>• {languages[lang].Note.Abou2}</h2>
        <h2>• {languages[lang].Note.Abou3}</h2>
    </div>
  )
}

export default About