import React, {useContext, useState}from 'react'
//import 'bootstrap/dist/css/bootstrap.min.css'
import '../page/page.css'
import Onion from './vefu/download (5).jfif'
import Tomato from './vefu/download.jfif'
import Potato from './vefu/download (1).jfif'
import Garlic from './vefu/download (2).jfif'
import Carrot from './vefu/download (10).jfif'
import Other from './vefu/images (72).jfif'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { appContext } from '../App'

function Vegetables() {
  const { t } = useTranslation();
  const {languages, lang} = useContext(appContext)
  return (
    <div className='uv'>
<div>
</div>
<div className='all'>
        <div className='sub'>
        <Link to='/Category/Onion'><img src={(Onion)} alt='profile'/></Link>
        <h1>{t(languages[lang].sub.Onion)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Tomato'><img src={(Tomato)}  alt='profile'/></Link>
        <h1>{t(languages[lang].sub.Tomato)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Potato'><img src={(Potato)}  alt='profile'/></Link>
        <h1>{t(languages[lang].sub.Potato)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Garlic'><img src={(Garlic)}  alt='profile'/></Link> 
        <h1>{t(languages[lang].sub.Garlic)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Carrot'><img src={(Carrot)}  alt='profile'/></Link>
        <h1>{t(languages[lang].sub.Carrot)}</h1> 
        </div>
        <div className='sub'>
        <Link to='/Category/otherVegetables'><img src={(Other)}  alt='profile'/></Link>
        <h1>{t(languages[lang].sub.oVegetables)}</h1> 
        </div>
    </div>
</div>
  )
}

export default Vegetables