import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl } from '../Constant'
import { appContext } from '../App'
import { useNavigate, Link, useParams } from 'react-router-dom'
import {ArrowLeft} from 'react-feather'
import Center from '../Center'


const delay = 10000;


export default function Favorite() {
    const history = useNavigate()
    const [products, setProducts] = useState([])
    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null); 
    const [advertiements, setAdvertiements] = useState([])
    const token = localStorage.getItem('Abulica')
    const {id} = useParams()
    useEffect(() => {
        axios({
            method: 'get',
            url: baseUrl + `/api/MyFavorite`,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            console.log(response.data)
            if(response.data.success){
                setProducts(response.data.products)
            }
        })

        axios({
            method: 'get',
            url: baseUrl + '/api/myAdvert',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            console.log(response.data)
            if(response.data.success){
                setAdvertiements(response.data.advertiements)
            }
        })

    },[])

    const unfavorite = (id) => {
        axios({
            method: 'get',
            url: baseUrl + '/api/un-Favorite/' + id,
            headers: {
                'Authorization': 'Bearer ' + token   
            }
        }).then(response => {
            if(response.data.success){
                alert('successfully removed!')
                window.location.reload()
            }
        })
    }

    function resetTimeout() {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      }
    
      React.useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
          () =>
            setIndex((prevIndex) =>
              prevIndex === advertiements.length - 1 ? 0 : prevIndex + 1
            ),
          delay
        );
    
        return () => {
          resetTimeout();
        };
      }, [index]);
    
  return (<div className='top'>
    <Link onClick={()=>history(-1)} ><ArrowLeft /></Link>
    
        <Center/>


        
        <div style={{
            display: 'flex',
            gap: 20,
            alignItems: 'center',
            justifyContent: 'space-evenly',
            justifyItems: 'center',
        }}>
    <div className='slideshow'>
    <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }} >
        {advertiements.map(adv  => (
                
                    <img className='slide' src={ baseUrl + adv.image}/>
               
            ))}
      </div>

    </div>
    </div>
    

    <div className="all" style={{ gap: 20}}>
        {products.length == 0 && <p> have no products yet!</p>}
       {products.map(product => (
         <div className='pro' style={{
            display: 'flex',
            flexDirection: 'column',
            color: 'black',
            backgroundColor: 'white', 
            gap :10,
            borderRadius: 5,
            padding: 10,
            alignSelf: 'start'
        }}>
            <img src={baseUrl + product.image} alt='' style={{
                width: 250,
                height: 200,
                objectFit: 'cover',
                borderRadius: '15px'
            }} />
            <p><h1>{product.name}</h1></p>
            <p><h1>ETB {product.price}</h1></p>
            <p><h1>call on {product.phone}</h1></p>
            <button onClick={() => unfavorite(product.id)}>Remove from Favorite</button>
          </div>
       ))} 
    </div>
    
    </div>
  )
}
