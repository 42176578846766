import React,{useContext} from 'react'
//import 'bootstrap/dist/css/bootstrap.min.css'
import '../page/page.css'
import Compostimage from './co/download (1).jfif'
import VermiWorm from './co/download.jfif'
import VermiCompost from './co/images (5).jfif'
import LiquidFertilizer from './co/images.jfif'
import OrganicProtection from './co/download (2).jfif'
import Seed from './co/download (39).jfif'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { appContext } from '../App'

function Compost() {
  const { t } = useTranslation();

  const {languages, lang} = useContext(appContext)
  return (
    <div className='uv'>
<div>
</div>
<div className='all'>
        <div className='sub'>
        <Link to='/Category/CompostSOil'><img src={(Compostimage)} className='AccImg' alt='profile'  /></Link>
        <h1>{t(languages[lang].sub.Compost)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Vermiw'><img src={(VermiWorm)} className='AccImg' alt='profile'  /></Link>
        <h1>{t(languages[lang].sub.VermiWorm)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/VermiC'><img src={(VermiCompost)} className='AccImg' alt='profile'  /></Link>
        <h1>{t(languages[lang].sub.VermiCompost)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Liquid'><img src={(LiquidFertilizer)} className='AccImg' alt='profile'  /></Link>
        <h1>{t(languages[lang].sub.LiquidFertilizer)}</h1> 
        </div>
        <div className='sub'>
        <Link to='/Category/Organicpro'><img src={(OrganicProtection)} className='AccImg' alt='profile'  /></Link> 
        <h1>{t(languages[lang].sub.OrganicProtection)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/seedling'><img src={(Seed)} className='AccImg' alt='profile'  /></Link> 
        <h1>{t(languages[lang].sub.seedling)}</h1>
        </div>
    </div>
</div>
  )
}

export default Compost