import React,{useContext} from 'react'
//import 'bootstrap/dist/css/bootstrap.min.css'
import '../page/page.css'
import FabaBean from './cepa/download (2).jfif'
import ChickenPeace from './cepa/images (3).jfif'
import Lentle from './cepa/download (3).jfif'
import Noug from './cepa/images (4).jfif'
import Flax from './cepa/images (5).jfif'
import Buna from './cepa/download (93).jfif'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { appContext } from '../App'

function Palls() {
  const { t } = useTranslation();
  const {languages, lang} = useContext(appContext)
  return (
    <div className='uv'>
<div>
</div>
<div className='all'>
        <div className='sub'>
        <Link to='/Category/FabaBean'><img src={(FabaBean)}  alt='profile'/></Link>
        <h1>{t(languages[lang].sub.bean)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/ChickenPeace'><img src={(ChickenPeace)}  alt='profile'/></Link>
        <h1>{t(languages[lang].sub.ChickenPeace)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Lentle'><img src={(Lentle)}  alt='profile'/></Link>
        <h1>{t(languages[lang].sub.Lentle)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Noug'><img src={(Noug)}  alt='profile'/></Link> 
        <h1>{t(languages[lang].sub.Noug)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Flax'><img src={(Flax)}  alt='profile'/></Link> 
        <h1>{t(languages[lang].sub.Flax)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Coffee'><img src={(Buna)}  alt='profile'/></Link> 
        <h1>{t(languages[lang].sub.coffee)}</h1>
        </div>
    </div>
</div>
  )
}

export default Palls