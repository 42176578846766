import React,{useContext} from 'react'
//import 'bootstrap/dist/css/bootstrap.min.css'
import '../page/page.css'
import Avocado from './vefu/download (4).jfif'
import Lemon from './vefu/download (6).jfif'
import Mango from './vefu/download (7).jfif'
import Orange from './vefu/download (11).jfif'
import Papaya from './vefu/images.jfif'
import Banana from './vefu/images (29).jfif'
import others from './vefu/images (28).jfif'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { appContext } from '../App'

function Fruits() {
  const { t } = useTranslation();
  const {languages, lang} = useContext(appContext)
  return (
    <div className='uv'>
<div>
</div>
<div className='all'>
        <div className='sub'>
        <Link to='/Category/Avocado'><img src={(Avocado)} className='AccImg' alt='profile'  /></Link>
        <h1>{t(languages[lang].sub.Avocado)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Lemon'><img src={(Lemon)} className='AccImg' alt='profile'  /></Link>
        <h1>{t(languages[lang].sub.Lemon)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Mango'><img src={(Mango)} className='AccImg' alt='profile'  /></Link>
        <h1>{t(languages[lang].sub.Mango)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Orange'><img src={(Orange)} className='AccImg' alt='profile'  /></Link> 
        <h1>{t(languages[lang].sub.Orange)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Papaya'><img src={(Papaya)} className='AccImg' alt='profile'  /></Link> 
        <h1>{t(languages[lang].sub.Papaya)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Banana'><img src={(Banana)} className='AccImg' alt='profile'  /></Link> 
        <h1>{t(languages[lang].sub.Banana)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/otherFruit'><img src={(others)} className='AccImg' alt='profile'  /></Link> 
        <h1>{t(languages[lang].sub.ofruit)}</h1>
        </div>
    </div>
</div>
  )
}

export default Fruits