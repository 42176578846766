import { appContext, homeContext } from '../App'
import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Home, Instagram , Bookmark, Users, Info, LogIn, UserPlus, LogOut, Globe} from 'react-feather';
import { useTranslation } from 'react-i18next';
import './category.css'
function Navbar(){
    const {token, setToken, setLang, lang} = useContext(appContext)
    const navigate= useNavigate()
    const { t, i18n } = useTranslation();
    // const {setLang} = useContext()

    const changeLanguage = (event) => {
        setLang(event.target.value)
        localStorage.setItem('Abulica-lang', event.target.value)
    };
    // const {data} = useContext(appContext)
    
    return (
        <div className='body'>
        <header>
       <div className='logo' >
           <Link to='/category' style={{marginRight: 10}}><h3><Menu size={35} color='black'/></h3></Link>
           <Link to='/'><h3><Home  size={30}  color='black'/></h3></Link>
       </div>
       <div className='dis'>
            <select onChange={changeLanguage}><Globe size={35} color='black'/>
              <option value={'en'} selected={lang == 'en'}>English</option>
              <option value={'tg'} selected={lang == 'tg'}>TIGRAY</option>
              <option value={'am'} selected={lang == 'am'}>AMHARIC</option>
              <option value={'or'} selected={lang == 'or'}>OROMIA</option>
            </select>
            <Link to='/About'><h3><Info size={35} color='black'/></h3></Link>
            { token ?  (
                <>
                <button onClick={() => {
                    localStorage.removeItem('Abulica')
                    setToken(null)
                    navigate('/SignIn')
                }}><LogOut/></button>
                <Link to='/Favorite'><h3><Bookmark size={35} color='black'/></h3></Link>
                <Link to='/MyPro'><h3><Users size={35} color='black'/></h3></Link>
                </>
            ) : (
                <>
                    <Link to='/SignIn' ><LogIn size={35} color='black'/></Link>
                    <Link to='/register'><UserPlus size={35} color='black'/></Link>
                </>
            )
            }
       </div>
    
    </header>
    </div>
    )
}


export default Navbar