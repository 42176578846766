import React from 'react'
import Function from './Fun'
import {useState} from 'react'
import {ArrowLeft} from 'react-feather'
import { Link , useNavigate} from 'react-router-dom';
import  { useContext } from 'react'
import axios from 'axios';
import {appContext} from '../App'
import './auth.css'
import { baseUrl } from '../Constant';
import './auth.css'
//back
 function ForgetPass() {
  const [value, setValue]= useState ({
    emil: '',
  })
  
  const [error, setError]= useState({})
  function handleChange(e){
    setValue({...value,[e.target.name]: e.target.value})
  }
  async function handleSubmit(event) {
    // e.preventDefault();
    event.preventDefault()
    let email = event.target.email.value
    let response = await axios({
        method: 'post',
        url: baseUrl + '/api/forget',
        data: {
            email
        }
    })
    console.log('hello')
    console.log(response.data)
    if(response.data.success){
        alert('Link successfully sent!')
    } else {
        setError(true)
        
    }
  }
    const {setToken} = useContext(appContext)
    
    const navigate = useNavigate()
  
  const history = useNavigate()
  return (
    <div className='bodyCard'>
    <div className='homeCard'>
    <Link onClick={()=>history(-1)} ><ArrowLeft /></Link>
        <form onSubmit={handleSubmit} method='' action=''>
            <div className='SideCard'>
            <input  className='input' type='email' placeholder='Enter Email'  value={value.email} name='email' onChange={handleChange}/>
            {error.name && <p style={{color:"red", fontSize: "13px" }}>{error.email}</p>}
            </div>
            <div className='button'>
              <button className='btn'>Send Code</button>
            </div>
        </form>
    </div>
   </div>  
  )
}
export default ForgetPass