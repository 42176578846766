import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './page/Navbar'  

export default function Body() {
  return (
    <div  style={{display: 'flex', height: '100vh', flexDirection: 'column'}}>
      <Navbar/>
      <Outlet />
      
      {/* <Main /> */}
      {/* <h1>this is a footer</h1> */}
      <p></p>
    </div>
  )
}
