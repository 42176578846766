import React, {useContext, useEffect, useState} from "react";
import { baseUrl } from "../Constant";
import axios from "axios";
import { appContext } from "../App";
import { Link, useNavigate } from "react-router-dom";
import {Edit, Trash2, ArrowLeft} from 'react-feather'

const delay = 10000;

export default function User() {
    const history = useNavigate()
    const [products, setProducts] = useState([])
    const [index, setIndex] = React.useState(0);
   const timeoutRef = React.useRef(null); 
   const [advertiements, setAdvertiements] = useState([])
    const token = localStorage.getItem('Abulica')
    useEffect(() => {
        axios({
            method: 'get',
            url: baseUrl + '/api/myProduct',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            console.log(response.data)
            if(response.data.success){
                setProducts(response.data.products)
            }
        })

        axios({
            method: 'get',
            url: baseUrl + '/api/myAdvert',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            console.log(response.data)
            if(response.data.success){
                setAdvertiements(response.data.advertiements)
            }
        })

    },[])

  const deleteProduct = async (id) => { 
   await fetch(`${baseUrl}/api/deleteProduct/${id}`, {
        method: 'get', 
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(() => {
        alert("Post deleted!");
        setProducts(products.filter(pr => pr.id != id))
      });
   
  }   


  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === advertiements.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);



    return (
        //logout fun and profile
        <div className='top'>
            <Link onClick={()=>history(-1)} ><ArrowLeft /></Link>

            <div className="all" style={{ gap: 20, display: 'flex', 'flexDirection': 'column'}}>
        <div style={{
            display: 'flex',
            gap: 20,
            alignItems: 'center',
            justifyContent: 'space-evenly',
            justifyItems: 'center',
        }}>
    <div className='slideshow'>
    <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }} >
        {advertiements.map(adv  => (
                
                    <img className='slide' src={ baseUrl + adv.image}/>
               
            ))}
      </div>

    </div>
    </div>
    </div>
            
            <div className="all" style={{ gap: 20}}>
            {products.length == 0 && <p>You have no products yet!</p>}
           {products.map(product => (
            <div className='pro' style={{
                display: 'flex',
                flexDirection: 'column',
                gap :10,
                color: 'black',
                backgroundColor: 'white', 
                borderRadius: 5,
                padding: 10,
                alignSelf: 'start',
               
            }}  key={product.id}>
                <img src={baseUrl + product.image} alt='' style={{
                    width: 250,
                    height: 200,
                    objectFit: 'cover',
                    borderRadius: '15px'
                }} />
                <p><h1>{product.name}</h1></p>
                <p><h1>ETB {product.price}</h1></p>
                <p><h1>call on {product.phone}</h1></p>
                <div> 
                 <button>  <Link to={`/Edit/${product.id}`}><Edit color="orange"/></Link></button> 
                </div>
                <div> 
                    <button onClick={() => deleteProduct(product.id)}><Trash2 color="red"/></button>
                </div>
            </div>
           ))} 
        </div>
        </div>
      )
}