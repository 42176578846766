const languages = {
    am: {
        "category": { 
            "vegetables": "አትክልት",
            "fruit": "ፋራፍሬ",
            "Animals": "እንስሳት",
            "AnimalsProduct": "የእንስሳት ተዋፅዖ",
            "Palls": "የሰብል እህሎች",
            "Cereals": "ጥራጥሬ",
            "Compost": "የአፋር ማዳበርያ"
          },
          "sub": {
            "Chicken": "ዶሮ",
            "Sheep": "በግ",
            "Ox": "በሬ",
            "Donkey": "አህያ",
            "AnimalsO": "ሌሎች እንስሳት",
            "Egg": "እንቁላል",
            "Milk": "ወተት",
            "Cheese": "አይብ",
            "Butter": "ቅቤ",
            "bean": "ባቄላ",
            "ChickenPeace": "ሽንብራ",
            "Lentle": "ምስር",
            "Noug": "ኑግ",
            "Flax": "ሰሊጥ",
            "Avocado": "አቮካዴ",
            "Lemon": "ሎሚ",
            "Mango": "ማንጎ",
            "Orange": "ብርቱካን",
            "Papaya": "ፓፓያ",
            "Tef": "ጤፍ",
            "Wheat": "ስንዴ",
            "Onion": "ቀይ ሽንኩርት",
            "Tomato": "ቲማቲም",
            "Potato": "ድንች",
            "Garlic": "ነጭ ሽንኩርት",
            "Carrot": "ካሮት",
            "otherAnimals": "ልሎች እንስሳት",
            "Corn": "በኮሎ",
            "Barley": "ገብስ",
            "Sorghum": "ዳጉሳ",
            "Compost": "ኮምፖስት",
          "VermiWorm": "ቨርሚ ",
          "VermiCompost": "ቨርሚ ኮምፖስት",
          "LiquidFertilizer": "ፈሳሽ ማዳበሪያ",
          "OrganicProtection": "ፀረ ተባይ",
          "seedling": "ችግኝ",
          "Banana": "ሙዝ",
           "coffee": "ቡና",
           "oVegetables": "ልሎች አትክልቶች",
           "ofruit": "ልሎች ፋራፍሬዎች",
          },
          "Note": {
            "Abou":"በኢትዮጵያ አርሶ አደሮች፣  ከፊል አርብቶ አደሮች እና አርብቶ አደሮች ለሚፈልጉት አገልግሎት ተገቢውን መረጃ ለማግኘት እና የግብርና ምርቶቻቸውን ለተጠቃሚዎች ለማድረስ አስቸጋሪ ነው።",

            "Abou1": "የኢትዮጵያ መንግስት በዋናነት የግብርና ኤክስቴንሽን እና ባለድርሻ አካላት አገልግሎቱን ተደራሽ ለማድረግ፣ የመረጃ ልውውጥን እና የገበያ ትስስርን ለማመቻቸት የተቻላቸውን ሁሉ እየጣሩ ነው። ነገር ግን እስካሁን ድረስ በሚፈለገው ደረጃ እራሳቸው ቀጥታ መገናኘት አልቻሉም። ",
            
            "Abou2": "ይህ ድረ-ገጽ (የግንኙነት ቴክኖሎጂ) ለአምራች እና ለተጠቃሚዎች የተገናኙ እንዲሆኑ በማድረግ  ያለምንም ገደብ ወይም ተፅዕኖ የገበያ ድርድር እንዲያደርጉ እና ከምርታቸው የተሻለ ገቢ እንዲኖራቸው ታስቦ የተዘጋጀ ነው።",
            
            "Abou3":"ይህ ቴክኖሎጂ ቀላል እና ገላጭ ስለሆነ አምራቹም ሆነ ተገልጋዩ በቀላሉ ሊጠቀሙበት ይችላሉ ተብሎ ስለሚታመን አገልግሎቱ የተሻለ ይሆናል ተብሎ ይጠበቃል።"
          }
    },
    en: {
        "category": { 
            "vegetables": "Vegetables",
            "fruit": "fruit",
            "Animals": "Animals",
            "AnimalsProduct": "AnimalsProduct",
            "Palls": "Pulse",
            "Cereals": "Cereals",
            "Compost": "Compost"
          },
          "sub": {
            "Chicken": "Chicken",
            "Sheep": "Sheep and Goat",
            "Ox": "Ox and Cow",
            "Donkey": "Donkey",
            "AnimalsO": "AnimalsOthers",
            "Egg": "Egg",
            "Milk": "Milk",
            "Cheese": "Cheese",
            "Butter": "Butter",
            "bean": "Fababean",
            "ChickenPeace": "Chick pea",
            "Lentle": "Lentil",
            "Noug": "Noug",
            "Flax": "Flax",
            "Avocado": "Avocado",
            "Lemon": "Lemon",
            "Mango": "Mango",
            "Orange": "Orange",
            "Papaya": "Papaya",
            "Tef": "Tef",
            "Wheat": "Wheat",
            "Onion": "Onion",
            "Tomato": "Tomato",
            "Potato": "Potato",
            "Garlic": "Garlic",
            "Carrot": "Carrot",
            "otherAnimals": "otherAnimals",
            "Corn": "Corn",
            "Barley": "Barley",
            "Sorghum": "Sorghum",
            "VermiWorm": "VermiWorm ",
          "VermiCompost": "VermiCompost",
          "LiquidFertilizer": "LiquidFertilizer",
          "OrganicProtection": "Organic Plant Protection",
          "seedling": "Seedling",
          "Compost": "Compost",
          "Banana": "Banana",
           "coffee": "Coffee",
           "oVegetables": "Other Vegetables",
           "ofruit": "Other Fruit",
            
          }, "Note": {
            "Abou":"It is difficult in Ethiopia for farmers, agro-pastoralists and pastoralists to obtain proper information for the services they need, deliver their agricultural products to consumers.",

            "Abou1": "The Ethiopian government mainly the agricultural extension and stakeholders are trying their best to make services accessible, facilitate the information exchange and market linkage for these producers. But so far is not well connected without middle men.",
            
            "Abou2": "This website (communication technology) is developed to make for producer and consumers connected, so that they can do market negotiations without restrictions or influenced and is designed to benefit them a better income from their products.",
            
            "Abou3":"It is expected that the service will be better because it is hoped that both the producer and the consumer will be able to use it easily because this interface technology is simple and descriptive."
          }
    },
    tg: {
      "category": { 
          "vegetables": "አትክልት ገደና",
          "fruit": "ፍራምረ",
          "Animals": "ከፍቲ",
          "AnimalsProduct": "ተዋፅኦ እንስሳ",
          "Palls": "የሰብል እህሎች",
          "Cereals": "ዘራእቲ",
          "Compost": "ሓመድ መዳበርያ"
        },
        "sub": {
           "Chicken": "ዶርሆ",
           "Sheep": "በጊዕ/ ጥየል",
           "Ox": "ብዕራይ/ ላሕሚ",
           "Donkey": "ኣድጊ",
           "AnimalsO": "ካልኦት እንስሳት",
           "Egg": "እንቁላሊሕ",
           "Milk": "ፀባ",
           "Cheese": "አጅቮ",
           "Butter": "ጠስሚ",
           "bean": " ዓተር",
           "ChickenPeace": "ሽምብራ/ቀይሕ ዓተር",
           "Lentle": "ብርስን",
           "Noug": "ንሂግ",
           "Flax": "እንጣጢዕ",
           "Avocado": "አቮካደ",
           "Lemon": "ለሚን",
           "Mango": "ማንጎ",
           "Orange": "ኣራንሺ",
           "Papaya": "ፓፓያ",
           "Tef": "ጣፍ",
           "Wheat": "ስርናይ",
           "Onion": "ሽጉርቲ ቀይሕ",
           "Tomato": "ኮመደረ",
           "Potato": "ድንሽ",
           "Garlic": "ሽጉርቲ ፃዕዳ",
           "Carrot": "ካሮት",
           "Corn": "ዒልዎ",
           "Barley": "ስገም",
           "Sorghum": "ዳጉሸ",
           "Compost": "ኮምፖስት",
           "VermiWorm": "ቨርሚ ቨርሚ",
           "VermiCompost": "ቨርሚ ኮምፖስት",
           "LiquidFertilizer": "ፈሳሲ መዳበሪያ",
           "OrganicProtection": " ናይ ተፈጥሮ ምክልኻል ባልዕ",
           "seedling": "ፈልሲ",
           "Banana": "ሙዝ",
           "coffee": "ቡን",
           "oVegetables": "ካልኦት ኣሕምልቲ ገደና",
           "ofruit": "ካልኦት ቀዋሚ ተኽሊ",
           
        
         
        }, 
        "Note": {
          "Abou":"ሓረስቶት፣ መርባሕቲ እንስሳን ዝኣምሰሉን ኢትዮጵያ ዝደልይዎ ግልጋሎት መረዳእታ ሕርሻን ናብ ተጠቀምቲ ዝግበር ኣቅርቦት ምህርቲ ሕርሻን ኣፀጋሚ እዩ።",

          "Abou1": "መንግስቲ ኢትዮጵያ ብፍላይ ኤክስተንሽን ግብርናን መዳርግቲ ኣካላትን ነቱ ግልጋሎት ተበፃሓይ ንምግባር፣ ልውውጥ መረዳእታን፣ ምትእስሳር ዕዳጋን ምችው ንምግባር ዝከኣሎም ኩሉ ይገብሩ እዮም።  ኮይኑ ግና ሓረስቶትን መርባሕቲ እንስሳን እስካብ ሕዚ በቱ ዝድለ ደረጃ ቀጥታ እናተራኸቡ ስረሖም ከካይዱ ኣይከኣሉን።",
          
          "Abou2": "እቱ ድረ-ገጽ (ናይ መራኸቢ ቴክኖሎጂ) እዙይ ንመፍረይቲን ተጠቀምቲን ርክቦም ዘሐሸ ደረጃ ብምግባር ብዘይፀገም ወይ ፅዕንቶ ዋጋ ዕዳጋ እናገበሩ ካብ ምህርቲ ሕርሸኦም ዘሐሸ ዋጋ ንኽረኽቡ ብምሕሳብ ዝተዳለወ እዩ።",
          
          "Abou3":"እዙ ቴክኖሎጂ እዙይ ቀሊልን ስእላዊ ገላፂን ስለዝኾነ መፍረይቲ ኮነ ተገልገልቲ (ሸማታይ) ብቀሊሉ ክጥቀሙሉ ይኽእሉ እዮም ተባሂሉ ስለዝእመን እቱ ግልጋሎት ዘሐሸ ይኸውን ተባሂሉ ትፅቢት ይግበረሉ።"
        }
  }, 
  or: {
    "category": { 
      "vegetables": "Kuduraa",
        "fruit": "firiiwwaan",
        "Animals": "Horsiisa loonii",
        "AnimalsProduct": "Bu'aalee loonii",
        "Palls": "Midhaa dheedhii",
        "Cereals": "Midhaan nyaataa",
        "Compost": "Xaa'oo Uummamaa"
      },
      "sub": {
        "Chicken": "Lukkuu/Handaaqqoo",
        "Sheep": "Hoolaa fi Re'ee",
        "Ox": "Qotiyyoo   Sangaa fi Dhaltii",
        "Donkey": "Harree",
        "AnimalsO": " Beeyladoota Biroo",
        "Egg": "Hanqaaquu",
        "Milk": "Aannaan",
        "Cheese": "Ayibii",
        "Butter": "Dhadhaa",
        "bean": "Baaqelaa",
        "ChickenPeace": "Atara",
        "Lentle": "Missira",
        "Noug": "Nuugii",
        "Flax": "Talbaa",
        "Avocado": "Avookadoo",
        "Lemon": "Loomii",
        "Mango": "Maangoo",
        "Orange": "Burtukaana",
        "Papaya": "Paappayaa",
        "Tef": "Xaafii",
        "Wheat": "Qamadii",
        "Onion": "Shunkurtii",
        "Tomato": "Timaatima",
        "Potato": "Dinnicha",
        "Garlic": "Zinjibila",
        "Carrot": "Kaarotii",
        "otherAnimals": "Beeyladoota Biroo",
        "Corn": "Boqqoolloo",
        "Barley": "Garbuu",
        "Sorghum": "Mishingaa",
        "VermiWorm": "Raammoo Xaa'oo Uummamaa Oomishtu ",
          "VermiCompost": "Xaa'oo Uummamaa",
          "LiquidFertilizer": "xaa'oo Dhangala'aa",
          "Organic Protection": "Farra ilbiisotaa ti dhukkubaa kan uummamaa",
          "Compost": "Xaa'oo Uummamaa",
          "seedling": "Biqiltuu",
          "Banana": "Muuzii",
          "coffee": "bunaa",
          "oVegetables": " Kuduraalee Biroo",
          "ofruit": " firiiwwaan Biroo"
       
      }, "Note": {
        "Abou":"Itiyoophiyaa keessatti qonnaan bultoonni, Horsiisee bultoonnii fi gariin horsiisee bultoonni  odeeffannoo barbaachisaa ta’ee fi  tajaajila isaan barbaachisu argachuun oomisha qonnaa isaanii fayyadamtootaaf dhiheessuun rakkisaadha.",

        "Abou1": "Mootummaan Itiyoophiyaa, irra caalaa ekisteenshinii qonnaa fi qooda fudhattoonni tajaajila kana dhaqqabamaa taasisuuf, odeeffannoo waljijjiiruu fi walitti hidhamiinsa gabaa mijeessuuf waan danda’an hunda yaalaa jiru. Garuu hanga ammaatti namoota gidduu galeessa malee walitti hidhamiinsa gaarii akka hin qabne beekamaadha.",
        
        "Abou2": "Weebsaayitiin (teeknooloojii wal-qunnamtii) kun oomishtootni fi fayyadamtoonni akka wal-qunnamaniif kan qophaa’edha. Daangaa ykn dhiibbaa tokko malee haala gabaa irratti mari’achuun oomisha isaanii irraa galii fooyya’aa akka argataniif  kan qophaa’edha.",
        
        "Abou3":"Teeknooloojiin  kun salphaa fi ibsituu waan ta’eef oomishtoonnis ta’e fayyadamtootni salphaatti itti fayyadamuu akka danda’an waan amanamuuf tajaajilli kun fooyya’aa ta’a jedhamee eegama."
      }
}
}

export default languages    