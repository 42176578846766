import React, {useContext, useEffect, useState} from 'react'
import axios from 'axios'
import { baseUrl } from '../Constant'
import { appContext } from '../App'
import { useParams } from 'react-router-dom'

const delay = 10000;





function Avocado() {
    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);
    const [products, setProducts] = useState([])
    const [advertiements, setAdvertiements] = useState([])
    const {token} = useContext(appContext)
    const {Favorite, setFavorite} = useContext(appContext)
    const {type} = useParams()
    useEffect(() =>{
        axios ({
            method: 'get',
            url: baseUrl + '/api/getProducts/' + type,
        }).then(response => {
            if(response.data.success){
                setProducts(response.data.products)
            }
        })

        axios({
            method: 'get',
            url: baseUrl + '/api/myAdvert',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            console.log(response.data)
            if(response.data.success){
                setAdvertiements(response.data.advertiements)
            }
        })

    },[])

    const favorite = (id) => {
        axios({
            method: 'get',
            url: baseUrl + '/api/Favourite/' + id,
            headers: {
                'Authorization': 'Bearer ' + token   
            }

        }).then(response => {
            if(response.data.success){
                alert('Success')
            }
        })
        
    }

    function resetTimeout() {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      }
    
      React.useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
          () =>
            setIndex((prevIndex) =>
              prevIndex === advertiements.length - 1 ? 0 : prevIndex + 1
            ),
          delay
        );
    
        return () => {
          resetTimeout();
        };
      }, [index]);
   
  return (
    <div className='top'>
         <div className="all" style={{ gap: 20, display: 'flex', 'flexDirection': 'column'}}>
        <div style={{
            display: 'flex',
            gap: 20,
            alignItems: 'center',
            justifyContent: 'space-evenly',
            justifyItems: 'center',
        }}>
    <div className='slideshow'>
    <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }} >
        {advertiements.map(adv  => (
                
                    <img className='slide' src={ baseUrl + adv.image}/>
               
            ))}
      </div>

    </div>
    </div>
    </div>
    <div className="all" style={{ gap: 20}}>
        {products.length == 0 && <p> no products yet!</p>}
       
        {products.map(product => (
        <div className='pro' style={{
            display: 'flex',
            flexDirection: 'column',
            color: 'black',
            backgroundColor: 'white', 
            gap :10,
            borderRadius: 5,
            padding: 10,
            alignSelf: 'start',
        }}>
           
            <img src={baseUrl + product.image} alt='' style={{
                width: 250,
                height: 200,
                objectFit: 'cover',
                borderRadius: '15px'
            }} />
            <p><h1>{product.name}</h1></p>
            <p><h1>ETB {product.price}</h1></p>
            <p><h1>call on {product.phone}</h1></p>
            { token ?  (
                <>
                <button onClick={() => favorite(product.id)}>Favorite</button>
                </>
            ) : (
                <>
                  
                </>
            )
            }
          </div>
       ))} 
        </div>
       
    </div>
    
  )
}

export default Avocado