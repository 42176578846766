import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { baseUrl } from '../Constant'
import { appContext } from '../App'
import { Image, Edit2} from 'react-feather';
import { useTranslation } from 'react-i18next';



function Edit() {
    const [error, setError] = useState(false)
    const token = localStorage.getItem('Abulica')
    const navigate = useNavigate()
    const {id} = useParams();
    const formref = useRef()
    let nameref = useRef()
    let priceref = useRef()
    const { t } = useTranslation();
  const {languages, lang} = useContext(appContext)
    
    useEffect(() => {
        fetch(baseUrl + '/api/detail/'+id, {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(async response => {
            response = await response.json()
            console.log(response, '***')
            if(response.success){
                nameref.current.value = response.data.name
                priceref.current.value = parseInt(response.data.price)
                // callref.current.value = response.data.name
            } else {
                // setError(true)
            }
        })
        
    }, [])
    async function onSubmit(e){
        e.preventDefault()
        let name = e.target.name.value
        let price = e.target.price.value
        let image = e.target.image.files[0]
        let phone = e.target.phone.value
        let category = e.target.category.value
        let formdata = new FormData()
        formdata.append('name', name)
        formdata.append('price', price)
        formdata.append('product_image', image)
        formdata.append('phone', phone)
        formdata.append('category', category)



        let response = await fetch(baseUrl + '/api/Edit/'+id, {
            body: formdata,
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        response = await response.json()
        if(response.success){
            navigate('/myPro')
        } else {
            setError(true)
        }
    }
    return (
        <form onSubmit={onSubmit} ref={formref} className='homeCard' style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 30,
            borderRadius: 10,
            gap: 15,
            alignItems: 'center'
        }}>
            <h3>Edit Product</h3>
            <input className='input' type='text' ref={nameref} placeholder='Name' name='name' required/>
            <input className='input' type='number' ref={priceref}  id='price' placeholder='Price' name='price'  required/>
            <input className='input' type='number' placeholder='phone' name='phone' required/>
            <input type='file'  id="file"  name='image' required />
            <label for='file'><Image/></label>
            <select className='input' name="category" id="" required>
                <option value="Avocado">{t(languages[lang].sub.Avocado)}</option>
                <option value="Barley">{(languages[lang].sub.Barley)}</option>
                <option value="Butter">{t(languages[lang].sub.Butter)}</option>
                <option value="Carrot">{t(languages[lang].sub.Carrot)}</option>
                <option value="otherVegetables">{t(languages[lang].sub.oVegetables)}</option>
                <option value="otherFruit">{t(languages[lang].sub.ofruit)}</option>
                <option value="Banana">{t(languages[lang].sub.Banana)}</option>
                <option value="Coffee">{t(languages[lang].sub.coffee)}</option>
                <option value="Seedling">{t(languages[lang].sub.seedling)}</option>
                <option value="Cheese">{t(languages[lang].sub.Cheese)}</option>
                <option value="Chicken">{t(languages[lang].sub.Chicken)}</option>
                <option value="ChickenPeace">{t(languages[lang].sub.ChickenPeace)}</option>
                <option value="Compost">{t(languages[lang].category.Compost)}</option>
                <option value="Corn">{(languages[lang].sub.Corn)}</option>
                <option value="Donkey">{t(languages[lang].sub.Donkey)}</option>
                <option value="Egg">{t(languages[lang].sub.Egg)}</option>
                <option value="FabaBean">{t(languages[lang].sub.bean)}</option>
                <option value="Flax">{t(languages[lang].sub.Flax)}</option>
                <option value="Garlic">{t(languages[lang].sub.Garlic)}</option>
                <option value="Lemon">{t(languages[lang].sub.Lemon)}</option>
                <option value="Lentle">{t(languages[lang].sub.Lentle)}</option>
                <option value="Liquid">{t(languages[lang].sub.LiquidFertilizer)}</option>
                <option value="AnimalsO">{t(languages[lang].sub.AnimalsO)}</option>
                <option value="Mango">{t(languages[lang].sub.Mango)}</option>
                <option value="Milks">{t(languages[lang].sub.Milk)}</option>
                <option value="Noug">{t(languages[lang].sub.Noug)}</option>
                <option value="Onion">{t(languages[lang].sub.Onion)}</option>
                <option value="Orange">{t(languages[lang].sub.Orange)}</option>
                <option value="OrganicProtection">{t(languages[lang].sub.OrganicProtection)}</option>
                <option value="Ox">{t(languages[lang].sub.Ox)}</option>
                <option value="Papaya">{t(languages[lang].sub.Papaya)}</option>
                <option value="Potato">{t(languages[lang].sub.Potato)}</option>
                <option value="Sheep">{t(languages[lang].sub.Sheep)}</option>
                <option value="Sorghum">{(languages[lang].sub.Sorghum)}</option>
                <option value="Tefa">{(languages[lang].sub.Tef)}</option>
                <option value="Tomato">{t(languages[lang].sub.Tomato)}</option>
                <option value="VermiCompost">{t(languages[lang].sub.VermiCompost)}</option>
                <option value="VermiWorm">{t(languages[lang].sub.VermiWorm)}</option>
                <option value="Wheat">{(languages[lang].sub.Wheat)}</option>
            </select>
            <button className='btn' type='submit'><Edit2/></button>
        </form>
      )
    }

export default Edit
