import React from 'react'
import Function from './Fun'
import {useState} from 'react'
import {  Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { baseUrl } from '../Constant';
import './auth.css'

 function Register() {
  const navigate = useNavigate()
  async function onSubmit(event){
      event.preventDefault()
      let username = event.target.username.value
      let name = event.target.name.value
      let password = event.target.password.value
      let email = event.target.email.value
      let response = await axios({
          method: 'post',
          url: baseUrl + '/api/register',
          data: {
              username, name, password, email
          }
      })
      console.log(response.data)
      if(response.data.success){
          alert('Successfully created!')
          navigate('/SignIn')
      } else {
        alert('user already exists')
      }
  }
        return (
   <div className='bodyCard'>
    <div className='homeCard'>
        <form onSubmit={onSubmit}>
        <div className='SideCard'>
            <input className='input' type='text'  placeholder='Username' name='username' required/>
               </div>
            <div className='SideCard'>
            <input  className='input' type='text'  placeholder='name' name='name' required/>
               </div>
            <div className='SideCard'>
            <input  className='input' type='password'  placeholder='password' name='password' required/>
           </div>
            <div className='SideCard'>
            <input  className='input' type='text'  placeholder='email' name='email' required/>
           </div>
             <input type='submit' value={'Register'} className='btn' /> 
            <Link to='/SignIn' ><button className='btn' style={{ marginLeft: 8}}>SignIn</button></Link> <br/>
            <Link to='/Forget'>ForgetPassword</Link>
        </form>
    </div>
   </div>  
  )
}
export default Register