import React,{useContext} from 'react'
//import 'bootstrap/dist/css/bootstrap.min.css'
import '../page/page.css'
import Egg from './anp/images (3).jfif'
import Milks from './anp/images (4).jfif'
import Cheese from './anp/download (1).jfif'
import Butter from './anp/download (2).jfif'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { appContext } from '../App'

function AnimalsProduct() {
  const { t } = useTranslation();
  const {languages, lang} = useContext(appContext)
  return (
    <div className='uv'>
<div>

</div>
<div className='all'>
        <div className='sub'>
        <Link to='/Category/Egg'><img src={(Egg)}  alt='profile'/></Link>
        <h1>{t(languages[lang].sub.Egg)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Milk'><img src={(Milks)}  alt='profile'/></Link>
        <h1>{t(languages[lang].sub.Milk)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Cheese'><img src={(Cheese)}  alt='profile'/></Link>
        <h1>{t(languages[lang].sub.Cheese)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Butter'><img src={(Butter)}  alt='profile'/></Link> 
        <h1>{t(languages[lang].sub.Butter)}</h1>
        </div>
    </div>
</div>
  )
}

export default AnimalsProduct