import React, {useState, useEffect} from 'react'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import { createContext } from 'react';
import SeePost from './page/SeePost';
import Adpost from './page/AdPost';
import About from './page/About';
import Forget from './auth/Forget';
import Register from './auth/Register'
import Replace from './auth/Replace';
import SignIn from './auth/SignIn';
import Myproduct from './page/Myproduct'
//import SignOut from './auth/SignOut';
import Error from './Error';
import Vegetables from './Category/Vegetables';
import Animals from './Category/Animals'
import Palls from './Category/Palls';
import Friut from './Category/Fruit';
import AnimalsProduct from './Category/AnimalsProduct';
import Cereals from './Category/Cereals';
import Compost from './Category/Compost';
import Navbar from './page/Navbar';
import Category from './page/Category';
import AnimalsO from './subCategory/AnimalsOthers';
import Avocado from './subCategory/Avocado'
import  Image  from './page/slide';
import Body from './Body';
import { BrowserRouter,  Route, Routes } from 'react-router-dom';
import Favorite from './page/Favorite';
import  Edit  from './page/Edit';

import languages from './language';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Body />,
    children: [
      {
        path: '/',
        element: <SeePost/>
      },
      {
        path: 'SignIn',
        element: <SignIn />
      },
      {
        path: 'register',
        element: <Register />
      },
      {
        path: 'adPost',
        element: <Adpost/>
      },
      {
        path: 'Forget',
        element: <Forget />
      },
      {
        path: 'reset-password/:token',
        element: <Replace />
      },
      {
        path: 'myPro',
        element: <Myproduct />
      },
      {
        path: 'Category',
        element: <Category />
      },
      {
        path: 'Category/:type',
        element: <Avocado />
      },
      
      {
        path: 'Vegetables',
        element: <Vegetables />
      },
      {
        path: 'Animals',
        element: <Animals />
      },
      {
        path: 'Palls',
        element: <Palls />
      },
      {
        path: 'Friut',
        element: <Friut />
      },
      {
        path: 'Cereals',
        element: <Cereals />
      },
      {
        path: 'Compost',
        element: <Compost/>
      },
      {
        path: 'AnimalsProduct',
        element: <AnimalsProduct />
      },
      {
        path: 'About',
        element: <About />
      },
      {
        path: 'Favorite',
        element: <Favorite />
      },
      {
        path: '/Edit/:id',
        element: <Edit />
      },
      {
        path: '*',
        element: <Error />
      }
    ]
  }
])
export const appContext = createContext()
 
function App() {
  const [token, setToken]= useState(null)
  const [lang, setLang] = useState('am')
  const [Favorite, SetFavorite]=useState([])

  useEffect(() => {
    let  _token = localStorage.getItem('Abulica')
    let  _lang = localStorage.getItem('Abulica-lang')
    setLang(_lang || 'am')
    if(_token){
      setToken(_token)

    }
  }, [])
  return (
    <appContext.Provider value={{token, setToken, Favorite, SetFavorite, lang, setLang, languages}}>
      <RouterProvider router={router} />
    </appContext.Provider>

  );
}

export default App;
