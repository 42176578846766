import React, { useContext , useState} from 'react'
import { Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {appContext} from '../App'
import './auth.css'
import { baseUrl } from '../Constant';
import { ArrowLeft } from 'react-feather';
//forgot password



 function SignIn() {
  const [error, setError] = useState(false)
    const {setToken} = useContext(appContext)
    const navigate = useNavigate()
    async function onSubmit(event){
        event.preventDefault()
        let username = event.target.username.value
        let password = event.target.password.value
        let response = await axios({
            method: 'post',
            url: baseUrl + '/api/Login',
            data: {
                username, password
            }
        })
        console.log('hello')
        console.log(response.data)
        if(response.data.success){
            localStorage.setItem('Abulica', response.data.token)
            setToken(response.data.token)
            navigate('/')
        } else {
            setError(true)
            
        }
    }
  const history = useNavigate()
  return (
    <div className='bodyCard'>
    <div className='homeCard'>
   <Link onClick={()=>history(-1)} ><ArrowLeft /></Link>
        <form onSubmit={onSubmit}>
            {error &&<p>Incorrect name or password </p>}
            <div className='SideCard'>
            <input className='input' type='text'  placeholder='Username' name='username' required/>
           </div>
            <div className='SideCard'>
            <input  className='input' type='password'  placeholder='Password' name='password' required/>
            </div>
            <div className='button'>
            <input type='submit' value={'SignIn'} className='btn' /> 
             <Link to='/register' ><button className='btn' style={{ marginLeft: 8}}>Register</button></Link><br/>
             <Link to='/Forget'>ForgetPassword</Link>
            </div>
        </form>
    </div>
   </div>  
  )
}
export default SignIn