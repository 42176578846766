import React, {useContext, useState}from 'react'
import './page.css'
import fruits from './download (299).jfif'
import vegetables from'./download (37).jfif'
import animals from './images.jfif'
import animalsProduct from './download (4).png'
import grain from './images-_2_.png'
import Crops from './download-_1_.png'
import compost from './download-_4_.png'
import Navbar from './Navbar'
import { Link } from 'react-router-dom'
import Center from '../Center'
import { useTranslation } from 'react-i18next';
import { appContext } from '../App'

function Category() {
  const { t } = useTranslation();
  
  const {languages, lang} = useContext(appContext)
  return (
    
<div className='uv'>
<Center/>
<div className='all'>
   
        <div className='sub'>
        <Link to='/Vegetables'><img src={(vegetables)}  alt='profile'/></Link>
        <h1>{languages[lang].category.vegetables}</h1>
        </div>
        <div className='sub'>
        <Link to='/Friut'><img src={(fruits)}  alt='profile'/></Link>
        <h1>{t(languages[lang].category.fruit)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Animals'><img src={(animals)}  alt='profile'/></Link>
        <h1>{t(languages[lang].category.Animals)}</h1>
        </div>
        <div className='sub'>
        <Link to='/AnimalsProduct'><img src={(animalsProduct)}  alt='profile'/></Link>
        <h1>{t(languages[lang].category.AnimalsProduct)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Palls'><img src={(grain)}  alt='profile'/></Link>
        <h1>{t(languages[lang].category.Cereals)}</h1>
        </div>
        <div className='sub'> 
        <Link to='/Cereals'><img src={(Crops)}  alt='profile'/></Link>
        <h1>{t(languages[lang].category.Palls)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Compost'><img src={(compost)}  alt='profile'/></Link>
        <h1>{t(languages[lang].category.Compost)}</h1>
        </div>
    </div>
</div>
  )
}

export default Category