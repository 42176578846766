import React,{useContext} from 'react'
//import 'bootstrap/dist/css/bootstrap.min.css'
import '../page/page.css'
import Chicken from './anp/download (10).jfif'
import Sheep from './anp/images.jfif'
import Ox from './anp/images (1).jfif'
import Donkey from './anp/download.jfif'
import others from './anp/depositphotos_473610884-stock-photo-group-different-farm-animals-white.jpg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { appContext } from '../App'

function Animals() {
  const { t } = useTranslation();
  const {languages, lang} = useContext(appContext)
  return (
    <div className='uv'>
<div>
   
</div>
<div className='all'>
        <div className='sub'>
        <Link to='/Category/Chicken'><img src={(Chicken)}  alt='profile'/></Link>
        <h1>{t(languages[lang].sub.Chicken)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Sheep'><img src={(Sheep)}  alt='profile'/></Link>
        <h1>{t(languages[lang].sub.Sheep)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Ox'><img src={(Ox)}  alt='profile'/></Link>
        <h1>{t(languages[lang].sub.Ox)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/Donkey'><img src={(Donkey)}  alt='profile'/></Link> 
        <h1>{t(languages[lang].sub.Donkey)}</h1>
        </div>
        <div className='sub'>
        <Link to='/Category/AnimalsO'><img src={(others)}  alt='profile'/></Link>
        <h1>{t(languages[lang].sub.AnimalsO)}</h1> 
        </div>
    </div>
</div>
  )
}

export default Animals